import {
  SET_ACTIVE_BRANCH_STATE,
  SET_ACTIVE_COURSE_TYPE,
  SET_ACTIVE_LEARNING_TYPE,
  SET_SHOW_ENROLL_OVERLAY,
  SET_SHOW_OUR_COMMUNITY_OVERLAY,
} from "../../constants/home";

const home = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_LEARNING_TYPE:
      return {
        ...state,
        activeLearningType: action.payload,
      };
    case SET_ACTIVE_COURSE_TYPE:
      return {
        ...state,
        activeCourseType: action.payload,
      };
    case SET_SHOW_OUR_COMMUNITY_OVERLAY:
      return {
        ...state,
        showOurCommunityOverlay: !state?.showOurCommunityOverlay,
        selectedOurCommunitySlide: !state?.showOurCommunityOverlay
          ? action?.payload
          : undefined,
      };
    case SET_SHOW_ENROLL_OVERLAY:
      return {
        ...state,
        showEnrollOverlay: !state?.showEnrollOverlay,
        selectedBranchName: !state?.showEnrollOverlay
          ? action?.payload?.branch
          : undefined,
        selectedCourse: !state?.showEnrollOverlay
          ? action?.payload?.course
          : undefined,
      };
    case SET_ACTIVE_BRANCH_STATE:
      return {
        ...state,
        activeBranchState: action.payload,
      };
    default:
      return state;
  }
};

export default home;
